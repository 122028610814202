import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { confirmTrainerRelation as apiConfirmTrainerRelation } from '../../api/api-client'
import styles from './TrainerRelationConfirmation.module.scss'


const TrainerRelationConfirmation: FunctionComponent = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [queryParams] = useSearchParams()

  const confirmTrainerRelation = useCallback(
    async (token: string) => {
      try {
        await apiConfirmTrainerRelation({ token })
      } catch (err: any) {
        if (err.response && err.response.data) {
          const { statusCode } = err.response.data
          setErrorMessage(t(`common.error.error_${statusCode}`))
        } else {
          setErrorMessage(t('common.error.unknown'))
        }
      } finally {
        setIsLoading(false)
      }
    },
    [t, apiConfirmTrainerRelation]
  )

  useEffect(() => {
    ;(async () => {
      const token = queryParams.get('token') ?? ''
      await confirmTrainerRelation(token)
    })()
  }, [queryParams, confirmTrainerRelation])

  return (
    <div className={styles.container}>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      {isLoading && <div className={styles.loader} role="status"></div>}
      {!isLoading && !errorMessage && (
        <>
          <h2>{t('trainerRelationConfirmation.header')}</h2>
          <p>{t('trainerRelationConfirmation.message')}</p>
        </>
      )}
    </div>
  )
}

export default TrainerRelationConfirmation
