import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { confirmEmail } from '../../api/api-client'
import styles from './EmailConfirmation.module.scss'


const EmailConfirmation: FunctionComponent = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [queryParams] = useSearchParams()
  const isActive = queryParams.get('active')

  const confirmToken = useCallback(async (token: string) => {
    try {
      await confirmEmail(token)
    } catch (err: any) {
      if (err.response && err.response.data) {
        const { statusCode } = err.response.data
        setErrorMessage(t(`common.error.error_${statusCode}`))
      } else {
        setErrorMessage(t('common.error.unknown'))
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    (async () => {
      const token = queryParams.get('token') ?? ''
      await confirmToken(token)
    })()
  }, [queryParams])

  return (
    <div className={styles.container}>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      {isLoading && <div className={styles.loader} role="status"></div>}
      {!isLoading && !errorMessage && (
        <>
          <h2>{t('emailConfirmation.header')}</h2>
          {!isActive && <p>{t('emailConfirmation.message')}</p>}
        </>
      )}
    </div>
  )
}

export default EmailConfirmation
