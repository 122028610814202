import axios from 'axios'

import i18 from '../i18n/config'


const buildPath = (path: string): string => {
  const baseUrl: string = process.env.REACT_APP_API_URL ?? (window as any).API_URL

  return `${baseUrl}${path}`
}

const options = (): Record<string, unknown> => {
  const [language] = i18.language.split('-')

  return {
    headers: {
      'X-Language': language,
    },
  }
}

export const confirmEmail = async (token: string): Promise<void> => {
  return axios.get(buildPath(`/api/v1/auth/confirm/${token}`))
}

export const changePassword = async (data: {
  password: string
  confirmedPassword: string
  token: string
}): Promise<void> => {
  return axios.post(buildPath('/api/v1/auth/password-recovery'), data, options())
}

export const checkPassword = async (data: { password: string }): Promise<{ strength: number; hints: string[] }> => {
  return axios.post(buildPath('/api/v1/auth/check-password'), data, options()).then((response) => response.data)
}

export const activateUser = async (data: { token: string }): Promise<void> => {
  return axios.post(buildPath('/api/v1/auth/activate-user'), data)
}

export const confirmTrainerRelation = async (data: { token: string }): Promise<void> => {
  return axios.post(buildPath('/api/v1/athletes/trainer/confirm'), data)
}
