import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import deTranslation from './de/translation.json'
import enTranslation from './en/translation.json'


export const resources = {
  en: {
    translation: enTranslation,
  },
  de: {
    translation: deTranslation,
  },
} as const

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    interpolation: {
      escapeValue: true,
    },
    detection: {
      order: ['querystring', 'navigator'],
    },
  })
  .then((r) => console.log(`i18n initialized`))
  .catch((err) => console.error(err))

i18n.languages = ['en', 'de']
i18n.changeLanguage()

export default i18n
