import React, { FunctionComponent } from 'react'
import { AnimatePresence, motion } from 'framer-motion';

import {ReactComponent as Ellipse } from './ellipse.svg'

import styles from './Button.module.scss'

export type ButtonProps = {
  type: "button" | "submit"
  children: React.ReactNode
  loading?: boolean
}

const Button: FunctionComponent<ButtonProps> = ({ type, loading = false, children }) => {
  return <div>
    <button className={styles.button} type={type} disabled={loading}>
      {children}
      <AnimatePresence>
        {loading && (
          <motion.div className={styles.loadingBox} initial={{ opacity: 0.9 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Ellipse className={styles.spinner} />
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  </div>
}

export default Button