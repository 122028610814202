import { FunctionComponent } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import styles from './TextField.module.scss'

export type TextFieldProps = {
  type: 'text' | 'password'
  label: string
  placeholder: string
  register?: UseFormRegisterReturn
  errorMessage?: string
}

const TextField: FunctionComponent<TextFieldProps> = ({ type, label, placeholder, register, errorMessage }) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <input
        type={type}
        className={errorMessage ? [styles.textField__invalid, styles.textField].join(' ') : styles.textField}
        placeholder={placeholder}
        {...register}
      />
      <div className={styles.invalidLabel}>
        {errorMessage && errorMessage}
      </div>
    </div>
  )
}

export default TextField
