import { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { debounce } from 'lodash'

import { changePassword, checkPassword } from '../../api/api-client'
import { Button, TextField } from '../../components/forms'
import styles from './PasswordRecovery.module.scss'
import {ReactComponent as InformationIcon } from './information.svg'

type ChangePassword = {
  token: string
  password: string
  confirmedPassword: string
}

type ErrorType = {
  password: string;
  confirmedPassword: string;
}

const PasswordRecovery: FunctionComponent = () => {
  const { t } = useTranslation()
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [error, setError] = useState<ErrorType>({ password: '', confirmedPassword: '' })
  const [pending, setPending] = useState<boolean>(false)
  const [hint, setHint] = useState<string>('')
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false)
  const [queryParams] = useSearchParams()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors}
  } = useForm<ChangePassword>({
    defaultValues: { token: queryParams.get('token')! },
  })

  const validatePassword = async (data: ChangePassword): Promise<boolean> => {
    const { password, confirmedPassword } = data;

    try {
      const { strength, hints } = await checkPassword({ password })
      setPasswordStrength(strength)
      let passwordError = error.password
      let confirmedPasswordError = error.confirmedPassword

      if (strength < 3) {
        setError({ password: t('passwordReset.error.too_weak'), confirmedPassword: confirmedPasswordError })
        setHint(hints.join(' '))
        return false
      } else {
        passwordError = ''
        setHint('')
      }

      if (password !== confirmedPassword) {
        setError({ password: passwordError, confirmedPassword: t('passwordReset.error.not_match') })
        return false
      } else {
        confirmedPasswordError = ''
      }

      setError({ password: passwordError, confirmedPassword: confirmedPasswordError })
      return true
    } catch (err) {
      console.error(`ERROR: ${err}`)
      setErrorMessage('common.error.unknown')

      return false
    }
  };

  const onSubmit = async (data: ChangePassword) => {
    setPending(true)
    try {
      const isValid = await validatePassword(data)
      if (isValid) {
        await changePassword(data)
        setErrorMessage(undefined)
        reset()
        setIsPasswordChanged(true)
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        const { statusCode, validationErrors } = error.response.data
        if (validationErrors) {
          for (const property of Object.keys(validationErrors)) {
            const fieldName = property as keyof ChangePassword
            const newError = { ...error }
            newError[fieldName] = validationErrors[property];

            setError(newError)
          }
        } else {
          setErrorMessage(t(`common.error.error_${statusCode}`))
        }
      } else {
        setErrorMessage(t('common.error.unknown'))
      }
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    if (errors.password?.type === 'required') {
      setPasswordStrength(0)
    }
  }, [errors.password?.type])

  return (
    <div className={styles.container}>
      {isPasswordChanged ? (
        <h2>{t('passwordReset.changed')}</h2>
      ) : (
        <>
          <h2>{t('passwordReset.header')}</h2>
          <p>{t('passwordReset.message')}</p>
          {errorMessage && (
            <div>
              <p className={styles.error}>{errorMessage}</p>
            </div>
          )}
          <form onChange={debounce(handleSubmit(validatePassword), 1000)} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formField}>
              <TextField
                type="password"
                label={t('passwordReset.form.password.label')}
                placeholder={t('passwordReset.form.password.placeholder')}
                register={register('password', { required: t('passwordReset.error.required') })}
                errorMessage={error.password || errors.password?.message}
              />

              <div className={styles.strengthMeter}>
                <div className={styles.strengthMeterFill} data-strength={passwordStrength}></div>
              </div>

              {hint && (
                <>
                  <InformationIcon />
                  <span className={styles.hintMessage}>{hint}</span>
                </>
              )}
            </div>

            <div className={styles.formField}>
              <TextField
                type="password"
                label={t('passwordReset.form.confirmedPassword.label')}
                placeholder={t('passwordReset.form.confirmedPassword.placeholder')}
                register={register('confirmedPassword')}
                errorMessage={error.confirmedPassword}
              />
            </div>

            <Button type="submit" loading={pending}>{t('passwordReset.form.submit')}</Button>
          </form>
        </>
      )}
    </div>
  )
}

export default PasswordRecovery
