import { Route } from 'react-router'
import { BrowserRouter, Routes } from 'react-router-dom'

import { DefaultLayout } from './components/layout'
import AccountActivation from './features/AccountActivation'
import EmailConfirmation from './features/EmailConfirmation'
import PasswordRecovery from './features/PasswordRecovery'
import TrainerRelationConfirmation from './features/TrainerRelationConfirmation'
import './i18n/config'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/password-reset"
          element={
            <DefaultLayout>
              <PasswordRecovery />
            </DefaultLayout>
          }
        />
        <Route
          path="/confirm-email"
          element={
            <DefaultLayout>
              <EmailConfirmation />
            </DefaultLayout>
          }
        />
        <Route
          path="/activate-user"
          element={
            <DefaultLayout>
              <AccountActivation />
            </DefaultLayout>
          }
        />
        <Route
          path="/confirm-trainer"
          element={
            <DefaultLayout>
              <TrainerRelationConfirmation />
            </DefaultLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
