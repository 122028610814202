import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { activateUser } from '../../api/api-client'
import styles from './AccountActivation.module.scss'


const AccountActivation: FunctionComponent = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [queryParams] = useSearchParams()

  const sendRequest = useCallback(async (token: string) => {
    try {
      await activateUser({ token })
    } catch (err: any) {
      if (err.response && err.response.data) {
        const { statusCode } = err.response.data
        setErrorMessage(t(`common.error.error_${statusCode}`))
      } else {
        setErrorMessage(t('common.error.unknown'))
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const token = queryParams.get('token') ?? ''
      await sendRequest(token)
    })()
  }, [queryParams])

  return (
    <div className={styles.container}>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
      {isLoading && <div className={styles.loader} role="status"></div>}
      {!isLoading && !errorMessage && (
        <>
          <h2>{t('accountActivation.header')}</h2>
          <p>{t('accountActivation.message')}</p>
        </>
      )}
    </div>
  )
}

export default AccountActivation
