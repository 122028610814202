import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import LogoIcon from '../../assets/Myoact_Logoicon_white.png'
import Wordmark from '../../assets/Wordmark.svg'
import styles from './DefaultLayout.module.scss'


export type FunctionComponentProps = {
  children: React.ReactNode
}

const DefaultLayout: FunctionComponent<FunctionComponentProps> = ({ children }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          <img className={styles.navbar__logo} src={Wordmark} alt="logo" />
        </div>
      </nav>
      <div className={styles.content}>
        <main>{children}</main>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footer__container}>
          <p>
            © 2022 MYOACT GMBH &nbsp;
            <img src={LogoIcon} alt="small-logo" className={styles.footer__logo} /> &nbsp;{t('footer.copyright')}
          </p>
        </div>
      </footer>
    </div>
  )
}

export default DefaultLayout
